import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Table } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_PURSE_BY_OGANIZATION from 'graphql/queries/fetchPurseByOrganization.graphql';

import theme from './theme.scss';

type PurseByOrganizationQueryResponse = {
  purseByOrganization: {
    id: string;
    issuedTo: {
      id: string;
      firstName: string;
      lastName: string;
      userName: string;
    };
    issuedBy: {
      id: string;
      name: string;
    };
    amount;
    status;
    createdAt;
  }[];
};

type PurseByOrganizationQueryVariables = {
  organizationId: string;
};

type PurseTableProps = {
  organizationId: string;
  onPurseSelect: (purseId: string) => void;
};

const PurseTable: FC<PurseTableProps> = ({ organizationId, onPurseSelect }) => {
  const { loading, error, data } = useQuery<
    PurseByOrganizationQueryResponse,
    PurseByOrganizationQueryVariables
  >(FETCH_PURSE_BY_OGANIZATION, {
    variables: { organizationId },
  });

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const purses = data.purseByOrganization;

  return (
    <div className={theme.purseTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="4">USER NAME</Table.HeaderCell>
            <Table.HeaderCell width="4">ORGANIZATION</Table.HeaderCell>
            <Table.HeaderCell width="4">AMOUNT</Table.HeaderCell>
            <Table.HeaderCell width="4">STATUS</Table.HeaderCell>
            <Table.HeaderCell width="4">CREATED</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {(purses || []).map(purse => (
            <Table.Row
              key={purse.id}
              className={theme.itemRow}
              onClick={() => onPurseSelect(purse.id)}
            >
              <Table.Cell width="4">
                {purse.issuedTo.firstName} {purse.issuedTo.lastName}
              </Table.Cell>
              <Table.Cell width="4">{purse.issuedBy.name}</Table.Cell>
              <Table.Cell width="4">{purse.amount}</Table.Cell>
              <Table.Cell width="4">{purse.status}</Table.Cell>
              <Table.Cell width="4">
                {moment(purse.createdAt).format('HH:mm a DD MMMM YYYY')}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {purses && purses.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no purse entries to show.</div>
      )}
    </div>
  );
};

export default PurseTable;
