import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_ORGANIZATION from 'graphql/queries/fetchOrganization.graphql';

import UserTable from './UserTable';
import UserDetailModal from './UserDetailModal';
import ResetPurseModal from './ResetPurseModal';
import RechargePurseModal from './RechargePurseModal';

type OrganizationQueryResponse = {
  organization: {
    id: string;
    name: string;
  }[];
};

type OrganizationQueryVariables = {
  id: string;
};

const Organization: FC = () => {
  const { organizationId } = useParams();
  const [userOnDisplay, setUserOnDisplay] = useState<string>('');
  const [openResetPurseModal, toggleResetPurseModal] = useState<boolean>(false);
  const [openRechargePurseModal, toggleRechargePurseModal] = useState<boolean>(false);
  // const [_openCreateModal, toggeCreateModal] = useState<boolean>(false);

  const { loading, error, data } = useQuery<OrganizationQueryResponse, OrganizationQueryVariables>(
    FETCH_ORGANIZATION,
    {
      variables: {
        id: organizationId ? organizationId : '',
      },
    }
  );

  if (loading) return <Loader type="screen" />;

  if (error || !organizationId || !data || !data.organization.length)
    return <ErrorDisplay type="screen" />;

  const name = data.organization[0].name;

  return (
    <AdminLayout>
      <Title name={name} backButtonRoute="/organizations" />
      <Divider />
      <h2>QUICK LINKS</h2>
      <ActionPanel>
        <ActionPanel.Action
          name={'Reset Purses'}
          type="callback"
          callback={() => toggleResetPurseModal(true)}
        />
        <ActionPanel.Action
          name={'Recharge Purses'}
          type="callback"
          callback={() => toggleRechargePurseModal(true)}
        />
        {/* <ActionPanel.Action
          name={'Invite User'}
          type="callback"
          callback={() => toggeCreateModal(true)}
        /> */}
      </ActionPanel>
      <Divider />
      <h2>USERS</h2>

      <UserTable
        organizationId={organizationId}
        onUserSelect={userId => setUserOnDisplay(userId)}
      />

      {!!userOnDisplay ? (
        <UserDetailModal
          userId={userOnDisplay}
          organizationId={organizationId}
          onClose={() => setUserOnDisplay('')}
        />
      ) : null}

      {openResetPurseModal ? (
        <ResetPurseModal
          organizationId={organizationId}
          onClose={() => toggleResetPurseModal(false)}
        />
      ) : null}

      {openRechargePurseModal ? (
        <RechargePurseModal
          organizationId={organizationId}
          onClose={() => toggleRechargePurseModal(false)}
        />
      ) : null}
    </AdminLayout>
  );
};

export default Organization;
