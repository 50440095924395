import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import OrganizationTable from './OrganizationTable';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';
import PurseTable from './PurseTable';

const Organizations: FC = () => {
  const history = useHistory();
  const [organizationOnDisplay, setOrganizationOnDisplay] = useState<string>('');
  const [_purseOnDisplay, setPurseOnDisplay] = useState<string>('');
  const [_openCreateModal, toggeCreateModal] = useState<boolean>(false);

  return (
    <AdminLayout>
      <Title name="Organizations" backButtonRoute="/" />
      <Divider />
      <h2>QUICK LINKS</h2>
      <ActionPanel>
        <ActionPanel.Action
          name={'Create Organization'}
          type="callback"
          callback={() => toggeCreateModal(true)}
        />
      </ActionPanel>
      <Divider />
      <h2>HISTORY</h2>

      <OrganizationTable
        onOrganizationSelect={organizationId => history.push(`/organizations/${organizationId}`)}
      />
      {!!organizationOnDisplay ? (
        <PurseTable
          organizationId={organizationOnDisplay}
          onPurseSelect={userId => setPurseOnDisplay(userId)}
        />
      ) : null}
    </AdminLayout>
  );
};

export default Organizations;
