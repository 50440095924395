import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Table, Responsive } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_USERS from 'graphql/queries/fetchUsers.graphql';

import theme from './theme.scss';

type UsersQueryResponse = {
  users: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    createdAt: string;
  }[];
};

type UserTableProps = {
  onUserSelect: (userId: string) => void;
};

const UserTable: FC<UserTableProps> = ({ onUserSelect }) => {
  const { loading, error, data } = useQuery<UsersQueryResponse>(FETCH_USERS);

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const users = data.users;

  return (
    <div className={theme.userTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      {/*
      <Button className={theme.filter}>Today</Button>
      <Button className={theme.filter}>Past Week</Button>
      <Button className={theme.filter}>Upcoming</Button>
      */}
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="4">NAME</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
              EMAIL
            </Responsive>
            <Table.HeaderCell width="4">PHONE NUMBER</Table.HeaderCell>
            <Table.HeaderCell width="4">JOINED</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {users.map(user => (
            <Table.Row
              key={user.id}
              className={theme.itemRow}
              onClick={() => onUserSelect(user.id)}
            >
              <Table.Cell width="4">
                {user.firstName} {user.lastName}
              </Table.Cell>
              <Responsive as={Table.Cell} width="4" minWidth={992}>
                {user.email}
              </Responsive>
              <Table.Cell width="4">{user.phoneNumber}</Table.Cell>
              <Table.Cell width="4">
                {moment(user.createdAt).format('HH:mm a DD MMMM YYYY')}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {users && users.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no user entries to show.</div>
      )}
    </div>
  );
};

export default UserTable;
