import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Table, Responsive } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_ORGANIZATIONS from 'graphql/queries/fetchOrganizations.graphql';

import theme from './theme.scss';

type OrganizationsQueryResponse = {
  organization: {
    id: string;
    name: string;
    status: string;
    createdAt: string;
    numberOfUsers: number;
  }[];
};

type OrganizationTableProps = {
  onOrganizationSelect: (organizationId: string) => void;
};

const OrganizationTable: FC<OrganizationTableProps> = ({ onOrganizationSelect }) => {
  const { loading, error, data } = useQuery<OrganizationsQueryResponse>(FETCH_ORGANIZATIONS);

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const organizations = data.organization;

  return (
    <div className={theme.organizationTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      {}
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="4">NAME</Table.HeaderCell>
            <Table.HeaderCell width="4">STATUS</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
              CREATED AT
            </Responsive>
            <Table.HeaderCell width="4" textAlign="center">
              NUMBER OF USERS
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {organizations.map(organization => (
            <Table.Row
              key={organization.id}
              className={theme.itemRow}
              onClick={() => onOrganizationSelect(organization.id)}
            >
              <Table.Cell width="4">{organization.name}</Table.Cell>
              <Table.Cell width="4">{organization.status}</Table.Cell>
              <Responsive as={Table.Cell} width="4" minWidth={992}>
                {moment(organization.createdAt).format('HH:mm a DD MMMM YYYY')}
              </Responsive>
              <Table.Cell width="4" textAlign="center">
                {organization.numberOfUsers}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {organizations && organizations.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no organization entries to show.</div>
      )}
    </div>
  );
};

export default OrganizationTable;
