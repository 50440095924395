import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Divider, Message, Modal, Table } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';
import ValidatedInput from 'components/ValidatedInput';

import FETCH_USER_DETAIL from 'graphql/queries/fetchUserDetail.graphql';
import FETCH_USERS_BY_ORGANIZATION from 'graphql/queries/fetchUsersByOrganization.graphql';
import REMOVE_USER_FROM_ORGANIZATION from 'graphql/mutations/removeUserFromOrganization.graphql';
import RECHARGE_PURSE_AMOUNT from 'graphql/mutations/rechargePurseAmount.graphql';

import theme from './theme.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  createdAt: string;
  purse: {
    id: string;
    amount: string;
    history: {
      id: string;
      availableBalance: number;
      description: string;
      amount: number;
      transactionType: string;
      triggeredAt: string;
    }[];
  };
};

type UserQueryResponse = {
  user: User;
};

type UserQueryVariables = {
  id: string;
};

type UserDetailProps = {
  userId: string;
  organizationId: string;
  onClose: () => void;
};

type UserInformationProps = {
  user: User;
  organizationId: string;
  onClose: () => void;
};

const sections = [
  {
    key: 'information',
    name: 'Details',
    emoji: '📋',
  },
  {
    key: 'purse',
    name: 'Purse',
    emoji: '💰',
  },
];

const UserInformation: FC<UserInformationProps> = ({ user, organizationId, onClose }) => {
  const [removeUserFromOrganization, { loading: removeLoading, error: removeError }] = useMutation(
    REMOVE_USER_FROM_ORGANIZATION,
    {
      update(cache, { data: { removeUserFromOrganization } }) {
        const fetchUsersQuery = cache.readQuery<{ users: User[] }>({
          query: FETCH_USERS_BY_ORGANIZATION,
        });
        if (!fetchUsersQuery) return;
        const users = fetchUsersQuery.users.filter(
          user => user.id !== removeUserFromOrganization.id
        );
        cache.writeQuery({
          query: FETCH_USERS_BY_ORGANIZATION,
          data: { users },
        });
      },
    }
  );

  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>FIRST NAME</p>
        <p className={theme.value}>{user.firstName}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>LAST NAME</p>
        <p className={theme.value}>{user.lastName}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>GENDER</p>
        <p className={theme.value}>{user.gender}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>JOINED ON</p>
        <p className={theme.value}>{moment(user.createdAt).format('HH:mm a DD MMMM YYYY')}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>PHONE NUMBER</p>
        <p className={theme.value}>{user.phoneNumber}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>EMAIL</p>
        <p className={theme.value}>{user.email}</p>
      </div>
      {removeError ? <Message negative header="An error occured. Please try again later." /> : null}
      <Button
        basic
        loading={removeLoading}
        onClick={() =>
          removeUserFromOrganization({ variables: { userId: user.id, organizationId } }).then(() =>
            onClose()
          )
        }
      >
        Remove from Organization
      </Button>
    </div>
  );
};

const UserPurse: FC<UserInformationProps> = ({ user, organizationId, onClose }) => {
  const [amount, setAmount] = useState('0');
  const [rechargePurseAmount, { loading: rechargeLoading, error: rechargeError }] = useMutation(
    RECHARGE_PURSE_AMOUNT,
    {
      update(cache, { data: { rechargePurseAmount } }) {
        const fetchUserDetailQuery = cache.readQuery<{ user: User }>({
          query: FETCH_USER_DETAIL,
        });
        if (!fetchUserDetailQuery) return;
        const user = fetchUserDetailQuery.user;
        user.purse.amount += rechargePurseAmount;
        cache.writeQuery({
          query: FETCH_USER_DETAIL,
          data: { user },
        });
      },
    }
  );

  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>WALLET</p>
        <p className={theme.value}>{user.purse ? `₹ ${user.purse.amount}` : '-'}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>ENTER RECHARGE AMOUNT</p>
        <ValidatedInput
          validator="number"
          required={true}
          value={amount}
          onValueChange={value => setAmount(value)}
        />
      </div>
      {rechargeError ? (
        <Message negative header="An error occured. Please try again later." />
      ) : null}
      <Button
        basic
        loading={rechargeLoading}
        onClick={() =>
          rechargePurseAmount({
            variables: { purseId: user.purse.id, amount: parseInt(amount) },
          }).then(() => onClose())
        }
      >
        Recharge Amount
      </Button>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>HISTORY</p>
        <Table unstackable compact className={theme.itemTable} size="small">
          <Table.Header className={theme.itemHeader}>
            <Table.Row>
              <Table.HeaderCell width="4">DATE</Table.HeaderCell>
              <Table.HeaderCell width="3">TYPE</Table.HeaderCell>
              <Table.HeaderCell width="3">DESCRIPTION</Table.HeaderCell>
              <Table.HeaderCell width="3" textAlign="center">
                AMOUNT
              </Table.HeaderCell>
              <Table.HeaderCell width="3" textAlign="center">
                BALANCE
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body className={theme.itemBody}>
            {user.purse.history.reverse().map(h => (
              <Table.Row key={user.id} className={theme.itemRow}>
                <Table.Cell width="4">
                  {moment(h.triggeredAt).format('HH:mm a DD MMMM YYYY')}
                </Table.Cell>
                <Table.Cell width="3">{h.transactionType}</Table.Cell>
                <Table.Cell width="3">{h.description}</Table.Cell>
                <Table.Cell width="3" textAlign="center">
                  <span style={{ color: h.transactionType === 'DEBIT' ? 'red' : 'green' }}>
                    {h.transactionType === 'DEBIT' ? '-' : ''}
                    {h.amount}
                  </span>
                </Table.Cell>
                <Table.Cell width="3" textAlign="center">
                  {h.availableBalance}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

const UserDetailModal: FC<UserDetailProps> = ({ userId, organizationId, onClose }) => {
  const { loading, error, data } = useQuery<UserQueryResponse, UserQueryVariables>(
    FETCH_USER_DETAIL,
    {
      variables: { id: userId },
    }
  );

  const [sectionOnDisplay, setSectionOnDisplay] = useState(sections[0].key);

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data) return <ErrorDisplay />;

    const user = data.user;

    return (
      <div className={theme.content}>
        <div className={theme.navigation}>
          <p className={theme.modalName}>USER</p>
          {sections.map(section => (
            <div
              className={classNames(
                theme.sectionSelect,
                section.key === sectionOnDisplay ? theme.active : null
              )}
              onClick={() => setSectionOnDisplay(section.key)}
              key={`section-select-${section.key}`}
            >
              <span role="img" aria-label="notepad">
                {section.emoji}
              </span>{' '}
              <span className={theme.sectionName}>{section.name}</span>
            </div>
          ))}
        </div>

        {sectionOnDisplay === 'information' ? (
          <UserInformation user={user} organizationId={organizationId} onClose={onClose} />
        ) : null}
        {sectionOnDisplay === 'purse' ? (
          <UserPurse user={user} organizationId={organizationId} onClose={onClose} />
        ) : null}
      </div>
    );
  }

  return (
    <Modal open={true} size="small" className={theme.userDetailModal} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};

export default UserDetailModal;
