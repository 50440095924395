import React, { FC } from 'react';
import { Loader as SemanticLoader } from 'semantic-ui-react';

import theme from './theme.scss';

export type LoaderProps = {
  type?: 'screen' | 'block';
};

const Loader: FC<LoaderProps> = ({ type = 'block' }) => {
  return (
    <div className={theme.loader} style={{ height: type === 'screen' ? '100vh' : '100%' }}>
      <SemanticLoader active size="large" />
    </div>
  );
};

export default Loader;
