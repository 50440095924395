import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Modal } from 'semantic-ui-react';

import ValidatedInput from 'components/ValidatedInput';

import FETCH_USERS from 'graphql/queries/fetchUsers.graphql';
import CREATE_USER from 'graphql/mutations/createUser.graphql';

import theme from './theme.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
};

type CreateUserMutationResponse = {
  user: User;
};

type CreateUserMutationVariables = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
};

type CreateUserModalProps = {
  onClose: () => void;
};

const CreateUserModal: FC<CreateUserModalProps> = ({ onClose }) => {
  const [createUser, { loading, error }] = useMutation(CREATE_USER, {
    update(cache, { data: { createUser } }) {
      const fetchUsersQuery = cache.readQuery<{ users: User[] }>({
        query: FETCH_USERS,
      });
      if (!fetchUsersQuery) return;
      const users = fetchUsersQuery.users;
      cache.writeQuery({
        query: FETCH_USERS,
        data: {
          users: users.concat([createUser]),
        },
      });
    },
  });

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  function onSubmit() {
    createUser({
      variables: {
        firstName,
        lastName,
        email,
        gender,
        phoneNumber,
      },
    }).then(() => onClose());
  }

  function renderContent() {
    return (
      <div className={theme.content}>
        <div className={theme.information}>
          <h3>Create a User</h3>
          <div className={theme.field}>
            <p className={theme.label}>FIRST NAME</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={firstName}
              onValueChange={value => setFirstName(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>LAST NAME</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={lastName}
              onValueChange={value => setLastName(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>GENDER</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={gender}
              onValueChange={value => setGender(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>EMAIL</p>
            <ValidatedInput
              validator="email"
              required={true}
              value={email}
              onValueChange={value => setEmail(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>PHONE NUMBER</p>
            <ValidatedInput
              validator="phonenumber"
              required={true}
              value={phoneNumber}
              onValueChange={value => setPhoneNumber(value)}
            />
          </div>
          {error ? <Message error header="An error occured. Please try again later." /> : null}
          <Button primary loading={loading} onClick={() => onSubmit()}>
            Submit
          </Button>
          <Button basic disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal open={true} className={theme.createUserModal}>
      {renderContent()}
    </Modal>
  );
};

export default CreateUserModal;
