import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import theme from './theme.scss';

export type TitleProps = {
  name: string;
  backButtonRoute?: string;
};

const Title: FC<TitleProps> = ({ name, backButtonRoute }) => {
  function backButton() {
    if (!backButtonRoute || !backButtonRoute.length) return null;

    return (
      <Link to={backButtonRoute}>
        <div className={theme.navigateBack}>
          <Icon name="arrow left" />
        </div>
      </Link>
    );
  }

  return (
    <div className={theme.title}>
      {backButton()}
      <h1>{name}</h1>
    </div>
  );
};

export default Title;
