import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { logIn } from 'utils/auth';

import LoginForm from 'components/LoginForm';

import loginMutation from 'graphql/mutations/login.graphql';

const Login: FC = () => {
  const [login, { loading }] = useMutation(loginMutation);
  const [error, setError] = useState('');

  const onSubmit = async (username: string, password: string) => {
    try {
      const result = await login({
        variables: {
          username,
          password,
        },
      });
      setError('');
      const token = result.data.loginUser.tokenId;

      logIn(token);
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        setError('Please check your login credentials and try again.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  return <LoginForm onSubmit={onSubmit} loading={loading} error={error} />;
};

export default Login;
