import React, { FC, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import FoodCourtTable from './FoodCourtTable';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';

const Vendors: FC = () => {
  const [vendorOnDisplay, setVendorOnDisplay] = useState<string>('');
  // const [_openCreateModal, toggeCreateModal] = useState<boolean>(false);

  return (
    <AdminLayout>
      <Title name="Food Courts" backButtonRoute="/" />
      <Divider />
      <h2>QUICK LINKS</h2>
      <ActionPanel>
        {/* <ActionPanel.Action
          name={'Create Organization'}
          type="callback"
          callback={() => toggeCreateModal(true)}
        /> */}
      </ActionPanel>
      <Divider />
      <h2>HISTORY</h2>

      <FoodCourtTable onVendorSelect={vendorId => setVendorOnDisplay(vendorId)} />
    </AdminLayout>
  );
};

export default Vendors;
