import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Table } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_SETTLEMENTS from 'graphql/queries/fetchSettlements.graphql';

import theme from './theme.scss';

type SettlementsQueryResponse = {
  settlementSummary: {
    id: string;
    vendor: {
      name: string;
    };
    status: string;
    settlementDate: string;
    transactionDate: string;
    totalAmountDueToIllude: number;
    totalAmountDueToVendor: number;
    totalSettlementAmount: number;
  }[];
};

type SettlementTableProps = {
  onSettlementSelect: (settlementId: string) => void;
};

const SettlementTable: FC<SettlementTableProps> = ({ onSettlementSelect }) => {
  const { loading, error, data } = useQuery<SettlementsQueryResponse>(FETCH_SETTLEMENTS);

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const settlements = data.settlementSummary;

  return (
    <div className={theme.organizationTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      {}
      <Table unstackable compact className={theme.itemTable} size="small">
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="2">DATE</Table.HeaderCell>
            <Table.HeaderCell width="3">VENDOR</Table.HeaderCell>
            <Table.HeaderCell width="2" minWidth={992}>
              AMOUNT DUE TO US
            </Table.HeaderCell>
            <Table.HeaderCell width="2" minWidth={992}>
              AMOUNT DUE TO VENDOR
            </Table.HeaderCell>
            <Table.HeaderCell width="2">SETTLEMENT AMOUNT</Table.HeaderCell>
            <Table.HeaderCell width="1">STATUS</Table.HeaderCell>
            <Table.HeaderCell width="1">CYCLE</Table.HeaderCell>
            <Table.HeaderCell width="3">SETTLEMENT DATE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {settlements.map(settlement => (
            <Table.Row
              key={settlement.id}
              className={theme.itemRow}
              onClick={() => onSettlementSelect(settlement.id)}
            >
              <Table.Cell width="2">
                {moment(settlement.transactionDate).format('DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell width="3">{settlement.vendor.name}</Table.Cell>
              <Table.Cell width="2">₹ {settlement.totalAmountDueToIllude}</Table.Cell>
              <Table.Cell width="2">₹ {settlement.totalAmountDueToVendor}</Table.Cell>
              <Table.Cell width="2">₹ {settlement.totalSettlementAmount}</Table.Cell>
              <Table.Cell width="1">{settlement.status}</Table.Cell>
              <Table.Cell width="1">{'3 days'}</Table.Cell>
              <Table.Cell width="3">
                {settlement.settlementDate
                  ? moment(settlement.settlementDate).format('HH:mm a DD MMMM YYYY')
                  : '-'}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {settlements && settlements.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no settlement entries to show.</div>
      )}
    </div>
  );
};

export default SettlementTable;
