import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Divider, Table, Responsive, Input } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_USERS from 'graphql/queries/fetchUsersByOrganization.graphql';

import theme from './theme.scss';

type UsersQueryResponse = {
  usersByOrganization: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    createdAt: string;
    purse: {
      id: string;
      amount: number;
    };
  }[];
};

type UserTableProps = {
  organizationId: string | undefined;
  onUserSelect: (userId: string) => void;
};

const UserTable: FC<UserTableProps> = ({ onUserSelect, organizationId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { loading, error, data } = useQuery<UsersQueryResponse>(FETCH_USERS, {
    variables: {
      organizationId,
    },
  });

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const users = data.usersByOrganization;

  return (
    <div className={theme.userTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      {/*
      <Button className={theme.filter}>Today</Button>
      <Button className={theme.filter}>Past Week</Button>
      <Button className={theme.filter}>Upcoming</Button>
      */}
      <Divider hidden />
      <Input
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        icon="search"
        placeholder="Search..."
      />
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="4">NAME</Table.HeaderCell>
            <Table.HeaderCell width="4">WALLET AMOUNT</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
              PHONE NUMBER
            </Responsive>
            <Table.HeaderCell width="4">ADDED ON</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {users
            .filter(user =>
              `${user.firstName} ${user.lastName}`.toUpperCase().includes(searchTerm.toUpperCase())
            )
            .map(user => (
              <Table.Row
                key={user.id}
                className={theme.itemRow}
                onClick={() => onUserSelect(user.id)}
              >
                <Table.Cell width="4">
                  {user.firstName} {user.lastName}
                </Table.Cell>
                <Table.Cell width="4">{user.purse ? `₹ ${user.purse.amount}` : '-'}</Table.Cell>
                <Responsive as={Table.Cell} width="4" minWidth={992}>
                  {user.phoneNumber}
                </Responsive>
                <Table.Cell width="4">
                  {moment(user.createdAt).format('HH:mm a DD MMMM YYYY')}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {users && users.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no user entries to show.</div>
      )}
    </div>
  );
};

export default UserTable;
