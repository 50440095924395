import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Modal } from 'semantic-ui-react';

import ValidatedInput from 'components/ValidatedInput';

import FETCH_USERS from 'graphql/queries/fetchUsersByOrganization.graphql';
import RECHARGE_PURSE_AMOUNT_BY_ORGANIZATION from 'graphql/mutations/rechargePurseAmountByOrganization.graphql';

import theme from './theme.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  purse: {
    id: string;
    amount: number;
  };
};

type RechargePurseModalProps = {
  organizationId: string;
  onClose: () => void;
};

const RechargePurseModal: FC<RechargePurseModalProps> = ({ organizationId, onClose }) => {
  const [amount, setAmount] = useState('0');
  const [rechargePurseAmountByOrganization, { loading, error }] = useMutation(
    RECHARGE_PURSE_AMOUNT_BY_ORGANIZATION,
    {
      update(cache) {
        const fetchUsersQuery = cache.readQuery<{ users: User[] }>({
          query: FETCH_USERS,
        });
        if (!fetchUsersQuery) return;
        const users = fetchUsersQuery.users.map(user => {
          const newUser = { ...user };
          newUser.purse.amount += parseInt(amount);
          return newUser;
        });
        cache.writeQuery({
          query: FETCH_USERS,
          data: {
            users,
          },
        });
      },
    }
  );

  function onSubmit() {
    rechargePurseAmountByOrganization({
      variables: {
        organizationId,
        amount: parseInt(amount),
      },
    }).then(() => onClose());
  }

  function renderContent() {
    return (
      <div className={theme.content}>
        <div className={theme.information}>
          <h3>Reset User Purses</h3>
          <div className={theme.field}>
            <p className={theme.label}>ENTER AMOUNT</p>
            <ValidatedInput
              validator="number"
              required={true}
              value={amount}
              onValueChange={value => setAmount(value)}
            />
          </div>
          {error ? <Message error header="An error occured. Please try again later." /> : null}
          <Button loading={loading} onClick={() => onSubmit()}>
            Recharge
          </Button>
          <Button basic disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal open={true} className={theme.createUserModal}>
      {renderContent()}
    </Modal>
  );
};

export default RechargePurseModal;
