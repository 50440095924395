import React, { Component, FC } from 'react';
import { Link } from 'react-router-dom';

import theme from './theme.scss';

export type ActionButtonProps = {
  type: string;
  route?: string;
  callback?: () => any;
  name: string;
};

const ActionButton: FC<ActionButtonProps> = ({ type, route, callback, name }) => {
  if (type === 'link' && route) {
    return (
      <Link to={route}>
        <div className={theme.actionButton}>{name}</div>
      </Link>
    );
  }

  if (type === 'callback' && callback) {
    return (
      <div className={theme.actionButton} onClick={() => callback()}>
        {name}
      </div>
    );
  }

  return null;
};

class ActionPanel extends Component {
  static Action = ActionButton;

  render() {
    return <div className={theme.actionPanel}>{this.props.children}</div>;
  }
}

export default ActionPanel;
