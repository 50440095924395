import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Divider, Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_SETTLEMENT_DETAIL from 'graphql/queries/fetchSettlementDetail.graphql';

import theme from './theme.scss';

type Settlement = {
  id: string;
  status: string;
  vendor: {
    name: string;
  };
  settlementURN: string;
  settlementDate: string;
  totalSettlementAmount: number;
  totalRevenue: number;
  totalTcsDeduction: number;
  totalTaxAmountOnItem: number;
  totalTransactionFee: number;
  totalTaxAmountOnTransactionFee: number;
  transactionDate: string;
  orderCount: number;
  paymentMode: string;
  totalAmountDueToVendor: number;
  totalAmountDueToIllude: number;
};

type SettlementQueryResponse = {
  settlement: Settlement;
};

type SettlementQueryVariables = {
  id: string;
};

type SettlementDetailProps = {
  settlementId: string;
  onClose: () => void;
};

type SettlementInformationProps = {
  settlement: Settlement;
};

const sections = [
  {
    key: 'information',
    name: 'Details',
    emoji: '📋',
  },
];

const SettlementInformation: FC<SettlementInformationProps> = ({ settlement }) => {
  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>GENERATED AT</p>
        <p className={theme.value}>
          {moment(settlement.transactionDate).format('HH:mm a DD MMMM YYYY')}
        </p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>STATUS</p>
        <p className={theme.value}>{settlement.status}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TOTAL SETTLEMENT AMOUNT</p>
        <p className={theme.value}>{settlement.totalSettlementAmount}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>ORDER COUNT</p>
        <p className={theme.value}>{settlement.orderCount}</p>
      </div>
      {settlement.settlementDate ? (
        <>
          <Divider />
          <div className={theme.field}>
            <p className={theme.label}>SETTLEMENT TIME</p>
            <p className={theme.value}>
              {moment(settlement.settlementDate).format('HH:mm a DD MMMM YYYY')}
            </p>
          </div>
          <div className={theme.field}>
            <p className={theme.label}>SETTLEMENT URN</p>
            <p className={theme.value}>{settlement.settlementURN}</p>
          </div>
          <div className={theme.field}>
            <p className={theme.label}>PAYMENT MODE</p>
            <p className={theme.value}>{settlement.paymentMode}</p>
          </div>
        </>
      ) : null}
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>TOTAL AMOUNT DUE TO VENDOR</p>
        <p className={theme.value}>{settlement.totalAmountDueToVendor}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TOTAL AMOUNT DUE TO ILLUDE</p>
        <p className={theme.value}>{settlement.totalAmountDueToIllude}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>TOTAL REVENUE</p>
        <p className={theme.value}>{settlement.totalRevenue}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TOTAL TAX AMOUNT ON ITEMS</p>
        <p className={theme.value}>{settlement.totalTaxAmountOnItem}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TOTAL TCS DEDUCTION</p>
        <p className={theme.value}>
          {settlement.totalTcsDeduction ? settlement.totalTcsDeduction : '-'}
        </p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TOTAL TRANSACTION FEE</p>
        <p className={theme.value}>{settlement.totalTransactionFee}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TOTAL TAX AMOUNT ON TRANSACTION FEE</p>
        <p className={theme.value}>{settlement.totalTaxAmountOnTransactionFee}</p>
      </div>
    </div>
  );
};

const SettlementDetailModal: FC<SettlementDetailProps> = ({ settlementId, onClose }) => {
  const { loading, error, data } = useQuery<SettlementQueryResponse, SettlementQueryVariables>(
    FETCH_SETTLEMENT_DETAIL,
    {
      variables: { id: settlementId },
    }
  );

  const [sectionOnDisplay, setSectionOnDisplay] = useState(sections[0].key);

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data) return <ErrorDisplay />;

    // @ts-ignore
    const settlement = data.settlementSummary[0];

    return (
      <div className={theme.content}>
        <div className={theme.navigation}>
          <p className={theme.modalName}>SETTLEMENT</p>
          {sections.map(section => (
            <div
              className={classNames(
                theme.sectionSelect,
                section.key === sectionOnDisplay ? theme.active : null
              )}
              onClick={() => setSectionOnDisplay(section.key)}
              key={`section-select-${section.key}`}
            >
              <span role="img" aria-label="notepad">
                {section.emoji}
              </span>{' '}
              <span className={theme.sectionName}>{section.name}</span>
            </div>
          ))}
        </div>

        {sectionOnDisplay === 'information' ? (
          <SettlementInformation settlement={settlement} />
        ) : null}
      </div>
    );
  }

  return (
    <Modal open={true} size="small" className={theme.userDetailModal} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};

export default SettlementDetailModal;
