import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Table, Responsive } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_VENDORS from 'graphql/queries/fetchVendors.graphql';

import theme from './theme.scss';

type VendorsQueryResponse = {
  foodCourts: {
    id: string;
    name: string;
    status: string;
    createdAt: string;
  }[];
};

type VendorTableProps = {
  onVendorSelect: (organizationId: string) => void;
};

const OrganizationTable: FC<VendorTableProps> = ({ onVendorSelect }) => {
  const { loading, error, data } = useQuery<VendorsQueryResponse>(FETCH_VENDORS);

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const organizations = data.foodCourts;

  return (
    <div className={theme.organizationTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      {}
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="4">NAME</Table.HeaderCell>
            <Table.HeaderCell width="4">STATUS</Table.HeaderCell>
            <Table.HeaderCell width="4">CREATED AT</Table.HeaderCell>
            <Table.HeaderCell width="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {organizations.map(organization => (
            <Table.Row
              key={organization.id}
              className={theme.itemRow}
              onClick={() => onVendorSelect(organization.id)}
            >
              <Table.Cell width="4">{organization.name}</Table.Cell>
              <Responsive as={Table.Cell} width="4" minWidth={992}>
                {organization.status}
              </Responsive>
              <Table.Cell width="4">
                {moment(organization.createdAt).format('HH:mm a DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell width="4">
                <Button
                  className={classNames(theme.filter, theme.active)}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  Generate Invite Code
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {organizations && organizations.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no organization entries to show.</div>
      )}
    </div>
  );
};

export default OrganizationTable;
