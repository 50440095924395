import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Modal } from 'semantic-ui-react';

import FETCH_USERS from 'graphql/queries/fetchUsersByOrganization.graphql';
import RESET_PURSE_AMOUNT_BY_ORGANIZATION from 'graphql/mutations/resetPurseAmountByOrganization.graphql';

import theme from './theme.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  purse: {
    id: string;
    amount: number;
  };
};

type ResetPurseModalProps = {
  organizationId: string;
  onClose: () => void;
};

const ResetPurseModal: FC<ResetPurseModalProps> = ({ organizationId, onClose }) => {
  const [resetPurseAmountByOrganization, { loading, error }] = useMutation(
    RESET_PURSE_AMOUNT_BY_ORGANIZATION,
    {
      update(cache) {
        const fetchUsersQuery = cache.readQuery<{ users: User[] }>({
          query: FETCH_USERS,
        });
        if (!fetchUsersQuery) return;
        const users = fetchUsersQuery.users.map(user => {
          const newUser = { ...user };
          newUser.purse.amount = 0;
          return newUser;
        });
        cache.writeQuery({
          query: FETCH_USERS,
          data: {
            users,
          },
        });
      },
    }
  );

  function onSubmit() {
    resetPurseAmountByOrganization({
      variables: {
        organizationId,
      },
    }).then(() => onClose());
  }

  function renderContent() {
    return (
      <div className={theme.content}>
        <div className={theme.information}>
          <h3>Reset User Purses</h3>
          <p className={theme.message}>
            Do you want to reset all the user purses? This action is irreversible.
          </p>
          {error ? <Message error header="An error occured. Please try again later." /> : null}
          <Button color="red" loading={loading} onClick={() => onSubmit()}>
            Reset
          </Button>
          <Button basic disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal open={true} className={theme.createUserModal}>
      {renderContent()}
    </Modal>
  );
};

export default ResetPurseModal;
