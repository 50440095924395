import React, { FC, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import UserTable from './UserTable';
import UserDetailModal from './UserDetailModal';
import CreateUserModal from './CreateUserModal';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';

const Users: FC = () => {
  const [userOnDisplay, setUserOnDisplay] = useState<string>('');
  const [openCreateModal, toggeCreateModal] = useState<boolean>(false);

  return (
    <AdminLayout>
      <Title name="Users" backButtonRoute="/" />
      <Divider />
      <h2>QUICK LINKS</h2>
      <ActionPanel>
        <ActionPanel.Action
          name={'Create User'}
          type="callback"
          callback={() => toggeCreateModal(true)}
        />
      </ActionPanel>
      <Divider />
      <h2>HISTORY</h2>

      <UserTable onUserSelect={userId => setUserOnDisplay(userId)} />

      {!!userOnDisplay ? (
        <UserDetailModal userId={userOnDisplay} onClose={() => setUserOnDisplay('')} />
      ) : null}

      {openCreateModal ? <CreateUserModal onClose={() => toggeCreateModal(false)} /> : null}
    </AdminLayout>
  );
};

export default Users;
