import renderRoutes from 'utils/renderRoutes';
import App from 'components/App';
import { isAuthenticated } from 'utils/auth';
import dashboard from './dashboard/route';
import users from './users/route';
import notFound from './default/route';
import login from './login/route';
import organizations from './organizations/route';
import settlements from './settlements/route';
import organization from './organizations/organization/route';
import foodcourts from './foodcourts/route';

const routeConfigs = {
  redirects: [
    {
      path: '/',
      to: '/dashboard',
      exact: true,
    },
    {
      path: '/login',
      to: '/',
      exact: true,
      condition: isAuthenticated,
    },
  ],
  component: App,
  routes: [dashboard, users, login, organizations, organization, foodcourts, settlements, notFound],
};

export default () => {
  return renderRoutes(routeConfigs);
};
