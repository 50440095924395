import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Divider, Message, Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_USER_DETAIL from 'graphql/queries/fetchUserDetail.graphql';
import FETCH_USERS from 'graphql/queries/fetchUsers.graphql';
import REMOVE_USER from 'graphql/mutations/removeUser.graphql';

import theme from './theme.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  createdAt: string;
};

type UserQueryResponse = {
  user: User;
};

type UserQueryVariables = {
  id: string;
};

type UserDetailProps = {
  userId: string;
  onClose: () => void;
};

type UserInformationProps = {
  user: User;
  onClose: () => void;
};

const sections = [
  {
    key: 'information',
    name: 'Details',
    emoji: '📋',
  },
];

const UserInformation: FC<UserInformationProps> = ({ user, onClose }) => {
  const [removeUser, { loading: removeLoading, error: removeError }] = useMutation(REMOVE_USER, {
    update(cache, { data: { removeUser } }) {
      const fetchUsersQuery = cache.readQuery<{ users: User[] }>({ query: FETCH_USERS });
      if (!fetchUsersQuery) return;
      const users = fetchUsersQuery.users.filter(user => user.id !== removeUser.id);
      cache.writeQuery({
        query: FETCH_USERS,
        data: { users },
      });
    },
  });

  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>FIRST NAME</p>
        <p className={theme.value}>{user.firstName}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>LAST NAME</p>
        <p className={theme.value}>{user.lastName}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>GENDER</p>
        <p className={theme.value}>{user.gender}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>JOINED ON</p>
        <p className={theme.value}>{moment(user.createdAt).format('HH:mm a DD MMMM YYYY')}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>PHONE NUMBER</p>
        <p className={theme.value}>{user.phoneNumber}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>EMAIL</p>
        <p className={theme.value}>{user.email}</p>
      </div>
      {removeError ? <Message negative header="An error occured. Please try again later." /> : null}
      <Button
        basic
        loading={removeLoading}
        onClick={() => removeUser({ variables: { userId: user.id } }).then(() => onClose())}
      >
        Remove
      </Button>
    </div>
  );
};

const UserDetailModal: FC<UserDetailProps> = ({ userId, onClose }) => {
  const { loading, error, data } = useQuery<UserQueryResponse, UserQueryVariables>(
    FETCH_USER_DETAIL,
    {
      variables: { id: userId },
    }
  );

  const [sectionOnDisplay, setSectionOnDisplay] = useState(sections[0].key);

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data) return <ErrorDisplay />;

    const user = data.user;

    return (
      <div className={theme.content}>
        <div className={theme.navigation}>
          <p className={theme.modalName}>USER</p>
          {sections.map(section => (
            <div
              className={classNames(
                theme.sectionSelect,
                section.key === sectionOnDisplay ? theme.active : null
              )}
              onClick={() => setSectionOnDisplay(section.key)}
              key={`section-select-${section.key}`}
            >
              <span role="img" aria-label="notepad">
                {section.emoji}
              </span>{' '}
              <span className={theme.sectionName}>{section.name}</span>
            </div>
          ))}
        </div>

        {sectionOnDisplay === 'information' ? (
          <UserInformation user={user} onClose={onClose} />
        ) : null}
      </div>
    );
  }

  return (
    <Modal open={true} size="small" className={theme.userDetailModal} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};

export default UserDetailModal;
