import React, { PureComponent } from 'react';
import { Form, Icon, Input, Label } from 'semantic-ui-react';

import theme from './theme.scss';

type Value = string | number;
type InputType = {
  value: Value;
  onValueChange: (value: any) => void;
  required?: boolean;
  validator?: 'number' | 'decimal' | 'year' | 'email' | 'shortText' | 'longText' | 'phonenumber';
};

class ValidatedInput extends PureComponent<InputType> {
  static defaultProps = {
    required: false,
    validator: '',
  };

  state = {
    error: '',
    showMessage: false,
  };

  componentDidMount() {
    this.validate(this.props.value);
  }

  componentWillReceiveProps(newProps) {
    this.validate(newProps.value);
  }

  validate = (val: Value) => {
    const { required, validator } = this.props;

    const value = `${val}`;
    const decimalValidator = /^-?\d*(\.\d+)?$/;
    const numberValidator = /^[0-9]+$/;
    const yearValidator = /^[1-9][0-9][0-9][0-9]$/;
    const emailValidator = /\S+@\S+\.\S+/;
    const phoneValidator = /\d{10}/;

    switch (true) {
      case required && value === '':
        return this.setState({ error: 'This field is required.' });

      case validator === 'decimal' &&
        !decimalValidator.test(value) &&
        value !== '' &&
        value !== null:
        return this.setState({ error: 'Must be a valid number.' });

      case validator === 'shortText' && value.length > 200 && value !== '' && value !== null:
        return this.setState({ error: 'Length cannot exceed 200 characters' });

      case validator === 'longText' && value.length > 1000 && value !== '' && value !== null:
        return this.setState({ error: 'Length cannot exceed 1000 characters' });

      case validator === 'number' && !numberValidator.test(value) && value !== '' && value !== null:
        return this.setState({ error: 'Must be a valid number. Decimals are not allowed.' });

      case validator === 'year' && !yearValidator.test(value) && value !== '' && value !== null:
        return this.setState({ error: 'Must be a valid year. 4 digits long.' });

      case validator === 'email' && !emailValidator.test(value) && value !== '' && value !== null:
        return this.setState({
          error: 'Should be a valid email. Example: pepesilvia@mailroom.com',
        });

      case validator === 'phonenumber' &&
        !phoneValidator.test(value) &&
        value !== '' &&
        value !== null:
        return this.setState({ error: '10 digits long. No country codes and preceding zero.' });

      default:
        return this.setState({ error: '' });
    }
  };

  render() {
    const { value, onValueChange } = this.props;

    return (
      <Form.Field inline className={theme.validatedInput}>
        <Input
          value={value}
          className={theme.inputBox}
          onChange={e => onValueChange(e.target.value)}
          error={this.state.error.length > 0}
        />
        {this.state.error.length > 0 ? (
          <div
            className={theme.errorInfo}
            onClick={() => this.setState({ showMessage: !this.state.showMessage })}
          >
            <Icon name="help" />
          </div>
        ) : null}
        {this.state.error.length > 0 && this.state.showMessage ? (
          <Label size="small" pointing="above">
            {this.state.error}
          </Label>
        ) : null}
      </Form.Field>
    );
  }
}

export default ValidatedInput;
