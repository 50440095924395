import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import SettlementTable from './SettlementTable';
import SettlementDetailModal from './SettlementDetailModal';

import Title from 'components/Title';

const Settlements: FC = () => {
  const history = useHistory();
  const [settlementOnDisplay, setSettlementOnDisplay] = useState<string>('');

  return (
    <AdminLayout>
      <Title name="Settlements" backButtonRoute="/" />
      <Divider />
      <h2>HISTORY</h2>

      <SettlementTable onSettlementSelect={settlementId => setSettlementOnDisplay(settlementId)} />

      {!!settlementOnDisplay ? (
        <SettlementDetailModal
          settlementId={settlementOnDisplay}
          onClose={() => setSettlementOnDisplay('')}
        />
      ) : null}
    </AdminLayout>
  );
};

export default Settlements;
