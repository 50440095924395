import React, { FC } from 'react';

import AdminLayout from 'layouts/AdminLayout';
import { Divider } from 'semantic-ui-react';

import ActionPanel from 'components/ActionPanel';
import Title from 'components/Title';

import theme from './theme.scss';

const quickLinks = [
  {
    name: 'Users',
    route: '/users',
  },
  {
    name: 'Organizations',
    route: '/organizations',
  },
  {
    name: 'Vendors',
    route: '/vendors',
  },
  {
    name: 'Settlements',
    route: '/settlements',
  },
];

const Dashboard: FC = () => {
  return (
    <AdminLayout>
      <div className={theme.dashboard}>
        <Title name="Dashboard" />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          {quickLinks.map(link => (
            <ActionPanel.Action key={link.route} name={link.name} type="link" route={link.route} />
          ))}
        </ActionPanel>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
